import '../../styles/pages/spring-summer-2023/the-secrets-in-the-hot-sauce.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
// import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/spring-summer-2023-stories/more-stories"
import { Scrollbars } from 'react-custom-scrollbars';

import socialBanner from "../../images/spring-summer-2023/social-banners/the-secrets-in-the-hot-sauce.jpg";
import truffFamily from "../../images/spring-summer-2023/the-secrets-in-the-hot-sauce/truff-family.jpg";
import guillen from "../../images/spring-summer-2023/the-secrets-in-the-hot-sauce/guillen.png";
import ajluni from "../../images/spring-summer-2023/the-secrets-in-the-hot-sauce/ajluni.png";

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'Alumni Share Their journey to Creating the Truff Condiment Brand';
    var pageDesc = 'First came the Instagram handle, then came the hot sauce. Cal State Fullerton alumni share their journey to creating the Truff luxury condiment brand.';
    var slug = 'the-secrets-in-the-hot-sauce';

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-summer-2023">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`spring-summer-2023 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn">
          <div className="text">
            <h1 className="animated fadeInDown delay-1s">
              <span className='top'>The Secret’s<br />in the</span> <span className='middle'>Hot</span> <span className='bottom'>Sauce</span>
            </h1>
            <p className="sub-heading animated fadeInDown delay-1s">First came the Instagram handle, then came the hot sauce. Cal State Fullerton alumni share their journey to creating the Truff luxury condiment brand.</p>
            <span className="animated fadeInDown delay-1s byline">By Lynn Juliano / Photos courtesy of Truff / Design by Tofer Medina</span>
          </div>
        </div>
        <section id="hot-sauce-wrap">
          <Scrollbars
            className="scrollbars"
            renderView={props => <div {...props} className="scrollbar-view"/>}
            renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
            style={{ width: '100%', height: '100%' }}>
            
            <p><span className="intro-text">When Nick Guillen secured</span> the <a href="https://www.instagram.com/sauce/" target='_blank'>@sauce</a> Instagram handle in 2015, he and classmate Nick Ajluni quickly began to brainstorm how they could capitalize on the opportunity. They considered merchandise, a blog and salad dressing before landing on hot sauce.</p>

            <p>The Cal State Fullerton alumni are co-founders and co-CEOs of Truff, a luxury condiment brand known for its truffle-infused twists on hot sauce, pasta sauce, mayonnaise, truffle oil and salt. Ajluni ’15 (B.A. business administration) and Guillen ’15 (B.A. communications) share the excitement and challenges of starting a business from the ground up, their commitment to authenticity as business leaders, and how the CSUF Business Plan Competition was an invaluable part of their education.</p>
  
            <h2>How did you use social media to launch your business?</h2>
            <figure className='align-left align-outside circle'>
              <img src={ajluni} alt="" />
              <figcaption>Nick Guillen</figcaption>
            </figure>
            <p><strong>GUILLEN:</strong> When we got the Instagram name @sauce, we began to post content that centered around our love of pop culture and food. Our posts featured drool-worthy dishes in elaborate settings, and the account quickly gained a cult-like following of individuals eager to engage with the brand. This rapid success signaled an opportunity for us to expand the brand into a tangible, buzzworthy product. After a while, we wanted to create an upscale sauce for the audience that we had built. Our goal was to make a sauce that elevated the category as a whole. In our search for ingredients, we resonated with black truffle and found a way to incorporate it into the sauce. Our recipe uses a mix of real black truffle along with the highest quality black truffle oil available.</p>
  
            <h2>What was the scariest part of the process?</h2>
            <figure className='align-left align-outside circle'>
              <img src={guillen} alt="" />
              <figcaption>Nick Ajluni</figcaption>
            </figure>
            <p><strong>AJLUNI:</strong> When we started Truff in 2017, a lot of people doubted us. Whether it was the unique mixture of truffles and spice or the price point or our direct-to-customer roots — a lot of retailers did not think we were a viable business. Throughout all this, we remained confident in ourselves and our product. We knew that if we just got people to try Truff, they would understand that it deserved a prominent place in the hot sauce market. Today, we’ve built something much bigger than just a condiment. Truff is seen as a conversation point because of all the reasons that some originally doubted us.</p>

            <aside>
              <h2>Truff’s Award-Winning Products</h2>

              <figure>
                <img src={truffFamily} alt="" />
              </figure>

              <p>Since the release of its hot sauce in 2017, Truff quickly became one of the fastest-growing condiment brands. The company’s products are available nationwide and have been recognized by such publications as:</p>
              <ul>
                <li>Oprah’s Favorite Things, 2022, 2021, 2019, 2018</li>
                <li>People Magazine’s Food Awards: Best Mayonnaise, 2022</li>
                <li>Clean Eating’s Clean Choice Award: Best Pantry Staple, 2022</li>
                <li>Kitchn Essentials’ Essential Groceries: Best Mayonnaise, 2021</li>
                <li>NBC Today Show’s Best Selling Products, 2020</li>
                <li>Forbes’ 30 Under 30: Food and Drink, 2019</li>
              </ul>
            </aside>
  
            <h2>What programs at csuf were influential to you?</h2>
            <p><strong>AJLUNI:</strong> We found great value in the CSUF Business Plan Competition (now CSUF Startup Competition). I submitted NixFix Chasers, a powdered beverage, in the 2013 competition and placed second out of 80 contestants. This was an amazing experience for both of us, and it helped us learn a lot about what to think through when starting a business. Additionally, we both had the privilege of having the late Joe Formichelli (lecturer in management) as an instructor in college, and we loved his class.</p>
  
            <h2>What advice do you have for college students dreaming of starting their own business?</h2>
            <p><strong>GUILLEN:</strong> The challenge of building a business from the ground up is rewarding in itself. You have to love the process because it isn’t always glamorous. One of the most important things is to be authentic and not just focus on selling things to people. Your goal should be to provide as much value as humanly possible to your audience.</p>
  
            <h2>What s your favorite food to pair with hot sauce?</h2>
            <p>
              <strong>AJLUNI:</strong> Pizza and tacos.<br />
              <strong>GUILLEN:</strong> Omelets, pizza, mac and cheese.
            </p>
  
            <p className="learn-more">Support the Center for Entrepreneurship:<br /><a href="https://give.fullerton.edu/entrepreneurship" target="_blank" rel="noreferrer">give.fullerton.edu/entrepreneurship</a></p>
  
  
          </Scrollbars>
        </section>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 